import React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import * as styles from './Footer.module.scss';


interface Props {
  className?: string;
}
const Container: React.FC<Props> = ({ children, className }) => {

  return (
    <div className={clsx(styles.root, className)}>
      <span>
        ©2021 ShopMojo.app
      </span>
      <div className={styles.spacer}/>
      <Link to="/privacy">
        Privacy Policy
      </Link>
      <div className={styles.spacer}/>
      <Link to="/terms">
        Terms of Service
      </Link>
    </div>
  );
}

export default Container;